.logo {
  width: 170px;
}

.navbar {
  height: var(--nav-size);
  background-color: var(--bg);
}

.navbar-mobile {
  height: 48px;
  background-color: var(--bg);
}

.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button {
  --button-size: cal(var(--nav-size) * 0.5);
  height: var(--button-size);
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.icon-button svg path {
  fill: none;
  stroke: var(--text-color);
  width: 20px;
  height: 20px;
  transition: ease-in-out 0.2s;
}

.icon-button:hover svg path {
  stroke: #e95f00;
}

.icon-button span {
  white-space: nowrap;
  color: var(--text-color);
  transition: ease-in-out 0.2s;
}

.icon-button:hover span {
  color: #e95f00;
}

.dropdown {
  position: absolute;
  top: 64px;
  width: 207px;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.odditem {
  background-color: #f2f2f2;
}

.why-box {
  background-color: #fafafa;
  width: 90%;
}

.why-box img {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.icon {
  width: 200px;
}

.banner-text1 {
  color: #ffffff;
  font-family: shopee2021;
  font-size: 60px;
  line-height: 70px;
  letter-spacing: 0.02em;
  left: 10%;
  top: 30%;
}

.banner-text2 {
  color: #ffffff;
  font-size: 60px;
  line-height: 70px;
  letter-spacing: 0.02em;
  left: 10%;
  top: 50%;
  font-weight: 300;
  font-size: 22px;
  line-height: 36px;
}

.partner-box {
  background: #fafafa;
  height: 100%;
  margin: auto;
  width: 154px;
  height: 101px;
  display: flex;
}

.partner-container {
  display: grid;
  flex-wrap: wrap;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, 154px);
  grid-gap: 1rem;
  justify-content: space-evenly;
  justify-items: center;
}

.why-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-swiper.button-swiper-active {
  width: 13px;
  height: 13px;
  display: inline-block;
  border-radius: 50%;
  background: #c9c9c9;
  margin: 6.5px;
}

.button-swiper {
  width: 13px;
  height: 13px;
  display: inline-block;
  border-radius: 50%;
  background: #efefef;
  margin: 6.5px;
}

.my-custom-pagination-div {
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  margin-top: 2rem;
  cursor: pointer;
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus {
  outline: none !important;
}

a {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text-black {
  color: #202325;
}

.banner-text3 {
  position: absolute;
  left: 311px;
  top: 252px;

  font-family: Shopee2021;
  font-style: normal;
  font-weight: 800;
  font-size: 46px;
  line-height: 48px;
  /* or 104% */

  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #ffffff;
}
.title {
  font-family: Shopee2021;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  align-items: center;
  color: #333a41;
  margin-bottom: 1rem;
}

.text {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #797b7c;
  line-height: 28px;
}

.page-image {
  max-width: 327px;
  max-height: 553px;
}

.content-alt {
  background: linear-gradient(to top, white 15%, #fafafa 15% 85%, white 85%);
}

.content-alt-mobile {
  background: linear-gradient(to top, white 10%, #fafafa 10% 90%, white 90%);
}

.content-box {
  width: 55%;
  max-width: 914px;
  min-width: 827px;
}

.claim-box-1 {
  background: #ffffff;
  border: 1.5px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 24px;
  width: 319px;
}

.claim-button {
  border: 1.5px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 0.75rem;
  margin-top: 0.25rem;
  font-size: 16px;
  transition: ease-in-out 0.2s;
}

.claim-button:hover {
  border: 1.5px solid #ea5f00;
  color: #ea5f00;
}

.claim-button-open {
  border: 1.5px solid #ea5f00;
  color: #ea5f00;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 0.75rem;
  margin-top: 0.25rem;
  font-size: 16px;
}

.claim-box-1-container {
  display: grid;
  flex-wrap: wrap;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, 319px);
  grid-gap: 1rem;
  justify-content: space-evenly;
  justify-items: center;
}

.claim-box-2-container {
  display: none;
}

.claim-box-3 {
  width: 142.53px;
  height: 94.38px;
  background: #fafafa;
}

.claim-box-3-container {
  display: grid;
  flex-wrap: wrap;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, 142.53px);
  grid-gap: 1rem;
  justify-content: space-evenly;
  justify-items: center;
}

.payment-text {
  font-size: 22px;
  line-height: 164%;
  letter-spacing: 0.02em;
  color: #202325;
}

.cancel-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 187%;
  letter-spacing: 0.02em;
}

.contact-text-h {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #202325;
}

.contact-text-label {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  color: #202325;
}

.contact-text {
  font-size: 16px;
  line-height: 187%;
  letter-spacing: 0.02em;
  color: #202325;
}

.submit-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 161px;
  height: 47px;
  background: #ea5f00;
  border-radius: 24px;
  font-size: 16px;
}

.submit-button-disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 161px;
  height: 47px;
  background: #dfdfdf;
  border-radius: 24px;
  font-size: 16px;
}

h1 {
  font-family: shopee2021;
  font-weight: 700;
  color: #333a41;
  font-size: 28px;
  line-height: 40px;
}

@media (min-width: 768px) {
  .why-container {
    display: grid;
    flex-wrap: wrap;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, 288px);
    grid-gap: 1rem;
    justify-content: space-evenly;
    justify-items: center;
  }

  .why-box {
    background-color: #fafafa;
    width: 288px;
    height: 296px;
    padding-top: 5%;
  }

  .partner-box {
    background: #fafafa;
    height: 100%;
    margin: auto;
    width: 185px;
    height: 122.5px;
    display: flex;
  }

  .partner-container {
    display: grid;
    flex-wrap: wrap;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, 185px);
    grid-gap: 1rem;
    justify-content: space-evenly;
    justify-items: center;
  }

  h1 {
    font-size: 32px;
    line-height: 48px;
  }

  .claim-box-1 {
    background: #ffffff;
    border: 1.5px solid #eeeeee;
    box-sizing: border-box;
    border-radius: 24px;
    width: 609px;
  }

  .claim-box-1-container {
    display: grid;
    flex-wrap: wrap;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, 609px);
    grid-gap: 1rem;
    justify-content: space-evenly;
    justify-items: center;
  }

  .text {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #797b7c;
    min-width: 400px;
    line-height: 28px;
  }

  .claim-box-2-container {
    display: grid;
    flex-wrap: wrap;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, 373px);
    grid-gap: 1rem;
    justify-content: space-evenly;
    justify-items: center;
  }

  .claim-box-3 {
    width: 185px;
    height: 122.5px;
    background: #fafafa;
  }

  .claim-box-3-container {
    display: grid;
    flex-wrap: wrap;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, 185px);
    grid-gap: 1rem;
    justify-content: space-evenly;
    justify-items: center;
  }
}
