@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #797b7c;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --bg: #ffffff;
  --text-color: #333a41;
  --nav-size: 64px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}

@font-face {
  font-family: shopee2021;
  src: url("assets/fonts/Shopee2021-Bold.ttf");
}

b {
  font-weight: bold;
}

h3 {
  color: #333a41;
}

h3 a {
  color: blue;
  background-color: transparent;
  text-decoration: underline;
}
